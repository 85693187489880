import {JobsContainer, SearchContainer} from '../../components'

const AllJobs = () => {
    return <>
        <SearchContainer/>
        <JobsContainer/>
    </>
    
}

export default AllJobs