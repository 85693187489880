import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
  
  const BarChartComponent = ({ data }) => {
    return (
      <ResponsiveContainer width='100%' height={500}>
        <BarChart
          data={data}
          margin={{
            top: 50,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Bar dataKey='count' fill='#fdd54f' barSize={60} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  export default BarChartComponent